<script>
import api from "../../../boot/axios";
import SidebarLink from "./SidebarLink";
import { collapsed, toggleSidebar, sidebarWidth } from "./state";
import axios from "../../../boot/axios";

export default {
  props: {},
  components: { SidebarLink },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },
  data() {
    return {
      showMenu: false,
      showMenu2: false,
      userNameSidebar: "",
      userData: "",
      userSet: "",
      userRole: "",
      delayTime: true,
      currentName: "",
      currentPos: "",
      collapsed: false,
    };
  },
  created() {
    // this.userNameSidebar = localStorage.getItem("username")
    this.getUserType();
  },
  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu;
    },
    toggleShow2() {
      this.showMenu2 = !this.showMenu2;
    },
    async logout() {
      this.userNameSidebar = null;
      let r_token = localStorage.getItem("r_token");
      localStorage.removeItem("jwt");
      localStorage.removeItem("r_token");
      localStorage.removeItem("email");
      localStorage.removeItem("role");

      await api.post(
        "/auth/logout",
        { r_token: r_token },
        { withCredentials: true }
      );
      api.defaults.headers.common["Authorization"] = "";
      this.indx++;
      this.userSet = "";
      localStorage.clear();
      this.$router.push("/");
    },
    async getUserType() {
      this.currentName = localStorage.getItem("name");
      this.currentPos = localStorage.getItem("position");
      this.userData = this.$store.getters.getCurentUser;
      // console.log(this.userData, 'mounted');
    },
    delaySidebar() {
      if (this.collapsed == false) {
        setTimeout(() => {
          this.delayTime = true;
        }, 200);
      } else {
        this.delayTime = false;
      }
    },
  },
};
</script>

<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    <h1>
      <span v-if="delayTime">
        <img
          src="@/assets/logo.png"
          alt="TTZ"
          style="width: 50%; margin-left: 50px"
        />
      </span>
      <span v-else style="font-weight: 700"></span>
    </h1>

    <SidebarLink
      to="/home"
      icon="fas fa-home"
      style="font-size: 20px; margin-top: 70px"
      @click.prevent="showMenu = false"
      >Home</SidebarLink
    >
    <SidebarLink
      to="/order"
      icon="fa-solid fa-cow"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >Order</SidebarLink
    >
    <SidebarLink
      to="/delivery_man_live"
      icon="fa-solid fa-truck"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >Delivery Order</SidebarLink
    >

    <SidebarLink
      to=""
      icon="fa-solid fa-cubes"
      style="font-size: 20px; padding-top: 20px; padding-bottom: 20px"
      @click.prevent="toggleShow2()"
    >
      Inventory
      <span v-if="showMenu2 == false"
        ><i
          class="fa-solid fa-angle-right"
          src=""
          style="margin-left: 50px"
          alt="add"
        ></i
      ></span>
      <span v-else
        ><i
          class="fa-solid fa-chevron-down"
          src=""
          style="margin-left: 50px"
          alt="add"
        ></i
      ></span>
    </SidebarLink>
    <ul v-if="showMenu2 == true" id="dropdown-example" class="py-1 space-y-1">
      <template v-if="collapsed">
        <li>
          <SidebarLink
            to="/inventory"
            icon="fa-solid fa-cubes"
            style="font-size: 18px"
            >inventory List</SidebarLink
          >
        </li>
      </template>
      <template v-else>
        <li>
          <SidebarLink
            to="/inventory"
            icon="fa-solid fa-cubes"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Inventory List</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_ledger"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Inventory Register</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_issue"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Inventory Issue</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/intentory_issue_table"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Issue Table</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_load_live"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Item Load</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_transfer"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Item Transfer</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_item_add"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Item Master</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_conversion"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Conversion</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_issue_dev"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Inventory Issues</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_table_dev"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Issue Table</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/inventory_opening"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Inventory Opening</SidebarLink
          >
        </li>
      </template>
    </ul>

    <!-- <SidebarLink to="/transaction" icon="fa-solid fa-money-bill-transfer" style="font-size: 20px; margin-top: 5px" @click.prevent="showMenu = false">Transaction</SidebarLink> -->

    <SidebarLink
      to=""
      icon="fa-regular fa-credit-card"
      style="font-size: 20px; padding-top: 20px; padding-bottom: 20px"
      @click.prevent="toggleShow()"
    >
      Purchase
      <span v-if="showMenu == false"
        ><i
          class="fa-solid fa-angle-right"
          src=""
          style="margin-left: 50px"
          alt="add"
        ></i
      ></span>
      <span v-else
        ><i
          class="fa-solid fa-chevron-down"
          src=""
          style="margin-left: 50px"
          alt="add"
        ></i
      ></span>
    </SidebarLink>
    <ul v-if="showMenu == true" id="dropdown-example" class="py-1 space-y-1">
      <template v-if="collapsed">
        <li>
          <SidebarLink
            to="/purchase_table"
            icon="fa-solid fa-table-list"
            style="font-size: 18px"
            >Purchase Approval</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/purchase_req"
            icon="fas fa-solid fa-check-to-slot"
            style="font-size: 18px"
            >Purchase Request</SidebarLink
          >
        </li>
      </template>
      <template v-else>
        <li>
          <SidebarLink
            to="/purchase_req"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Purchase Requisition</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/purchase_table"
            icon="fa-solid fa-table-list"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Purchase Approval</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/rfq_table"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Purchase Table</SidebarLink
          >
        </li>
        <li>
          <SidebarLink
            to="/purchase"
            icon="fas fa-solid fa-check-to-slot"
            style="
              font-size: 18px;
              padding-left: 5px;
              border-left: 3px solid white;
              padding-top: 15px;
              padding-bottom: 15px;
            "
            >Purchase Report</SidebarLink
          >
        </li>
      </template>
    </ul>

    <SidebarLink
      to="/delivery_match"
      icon="fa-solid fa-rectangle-list"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >DO Match</SidebarLink
    >
    <SidebarLink
      to="/delivery_match_list"
      icon="fa-regular fa-handshake"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >DO Match List</SidebarLink
    >
    <SidebarLink
      to="/fixed_asset"
      icon="fa-solid fa-qrcode"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >Fiexed Asset</SidebarLink
    >
    <SidebarLink
      to="/fixed_asset_list"
      icon="fa-solid fa-qrcode"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >Fiexed Asset List</SidebarLink
    >
    <SidebarLink
      to="/settings"
      icon="fa-solid fa-gear"
      style="font-size: 20px; margin-top: 5px"
      @click.prevent="showMenu = false"
      >Settings</SidebarLink
    >
    <div style="" v-if="delayTime">
      <div
        style="
          position: fixed;
          bottom: 50px;
          border-top: 1px solid #a4adba;
          width: 225px;
        "
      >
        <div style="float: left; margin-left: 10px">
          <p style="font-size: 22px; color: #a4adba; font-weight: 700">
            {{ currentName }}
          </p>
        </div>

        <div
          class="tooltip"
          style="float: right; margin-right: 5px; margin-top: 5px"
        >
          <!-- <span class="tooltiptext">Log Out</span> -->
          <!-- <a class="" @click="logout" style="font-size:22px; color:white;"><i class="fas fa-solid fa-arrow-right-from-bracket"></i></a> -->
          <a @click="logout" style=""
            ><img
              class="logB"
              src="../../assets/logout_w.png"
              style="text-align: center; cursor: pointer; width: 17px"
          /></a>
        </div>
      </div>
      <div style="position: fixed; bottom: 33px; width: 190px">
        <div style="float: left; margin-left: 12px">
          <p style="font-size: 18px; color: #a4adba; font-weight: 500">
            @{{ currentPos }}
          </p>
        </div>
      </div>
    </div>

    <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="
        toggleSidebar();
        delaySidebar();
        showMenu = false;
      "
    >
      <i class="fas fa-angle-double-left" />
    </span>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #25262b;
  --sidebar-item-hover: #f8d766;
  --sidebar-item-active: #dbbe58;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em 0em 0.5em 0em;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
}

.sidebar h1 {
  height: 2.5em;
}

.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;

  color: rgba(255, 255, 255, 0.7);

  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.logB {
  width: 20px;
}
.logB:hover {
  outline: solid;
  outline-color: white;
  outline-offset: 2px;
}
</style>
