import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
import Login from "../views/login.vue";

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem("email") != null) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  if (isAuthenticated) {
    next();
  } else {
    next("/");
  }
}

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    // beforeEnter : guardMyroute,
  },
  {
    path: "/register_user",
    name: "RegisterUser",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterUser.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/order",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Order.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/delivery_man",
    name: "DeliveryMan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeliveryMan.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/delivery_man_live",
    name: "DeliveryManLive",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeliveryManLive.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/order_details/:id",
    name: "OrderDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OrderDetails.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Inventory.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_item_add",
    name: "InventoryItemAdd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InventoryItemAdd.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/intentory_issue_table",
    name: "IntentoryIssueTable",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/IntentoryIssueTable.vue"
      ),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_transfer",
    name: "InventoryTransfer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InventoryTransfer.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_issue",
    name: "InventoryIssue",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InventoryIssue.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_load",
    name: "InventoryOrderLoad",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InventoryOrderLoad.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_load_live",
    name: "InventoryOrderLoadLive",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/InventoryOrderLoadLive.vue"
      ),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_ledger",
    name: "InventoryLedger",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/InventoryLedger.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_conversion",
    name: "InventoryConversion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/InventoryConversion.vue"
      ),
    beforeEnter: guardMyroute,
  },
  {
    path: "/transaction",
    name: "Transaction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Transaction.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase",
    name: "PurchaseModule.vue",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PurchaseModule.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase_req",
    name: "PurchaseRequest",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PurchaseRequest.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/rfq/:id",
    name: "RFQ",
    component: () => import(/* webpackChunkName: "about" */ "../views/RFQ.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase_com_entry/:id",
    name: "PurchaseComparisoneEntry",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PurchaseComparisoneEntry.vue"
      ),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase_com_select/:id",
    name: "PurchaseComparisonSelect",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PurchaseComparisonSelect.vue"
      ),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase_order/:id",
    name: "PurchaseOrder",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PurchaseOrder.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase_pre_grn/:id",
    name: "PurchasePreGRN",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PurchasePreGRN.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/purchase_table",
    name: "PurchaseTable",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PurchaseTable.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/rfq_table",
    name: "RFQTable",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RFQTable.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/test",
    name: "Test",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Test.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/product_type",
    name: "ProductType",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductType.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/product_sub_type",
    name: "ProductSubType",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductSubType.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/delivery_live_home",
    name: "DeliveryManLiveHome",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/DeliveryManLiveHome.vue"
      ),
    beforeEnter: guardMyroute,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register.vue"),
  },
  {
    path: "/delivery_match",
    name: "DeliveryMatch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeliveryMatch.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/delivery_match_list",
    name: "DeliveryMatchList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DOlist.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/project",
    name: "Project",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterProject.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/cost_center",
    name: "CostCenter",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterCostCenter.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/line_type",
    name: "LineType",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterLineType.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/area",
    name: "MasterArea",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterArea.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/employee",
    name: "MasterEmployee",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterEmployee.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/journal_entry",
    name: "JournalEntry",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JournalEntry.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/journal_list",
    name: "JournalList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/JeList.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/supplier",
    name: "MasterSupplier",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MasterSupplier.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/payment_match_list",
    name: "PaymentMatchList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PaymentMatchList"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/ledger",
    name: "ledger",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Ledger.vue"),
  },
  {
    path: "/group",
    name: "group",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Group.vue"),
  },
  {
    path: "/sub_group",
    name: "sub_group",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SubGroup.vue"),
  },
  {
    path: "/fixed_asset",
    name: "fixed_asset",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FixedAsset.vue"),
  },
  {
    path: "/voucher_list",
    name: "voucher_list",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VoucherList.vue"),
  },
  {
    path: "/payment_match",
    name: "payment_match",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PaymentMatch.vue"),
  },
  {
    path: "/fixed_asset_list",
    name: "fixed_asset_list",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FixedAssetList.vue"),
  },
  {
    path: "/voucher_type",
    name: "voucher_type",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VoucherType.vue"),
  },
  {
    path: "/voucher",
    name: "voucher",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Voucher.vue"),
  },
  {
    path: "/cost_center",
    name: "CostCenter",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CostCenter.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/account_type",
    name: "AccountType",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AccountType.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/line_type",
    name: "LineType",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LineType.vue"),
    beforeEnter: guardMyroute,
  },

  // newly added changes by --- Dipto Datta

  {
    path: "/add_delivery_man",
    name: "Add_Delivery_Man",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/settings/add_delivery_manView.vue"
      ),
    beforeEnter: guardMyroute,
  },

  // FOR DEVS ONLY

  {
    path: "/inventory_issue_dev",
    name: "inventoryIssueDev",
    component: () => import("../views/Development/inventoryIssueView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_table_dev",
    name: "inventoryTableDev",
    component: () => import("../views/Development/inventoryIssueTableView.vue"),
    beforeEnter: guardMyroute,
  },
  {
    path: "/inventory_opening",
    name: "inventoryOpening",
    component: () =>
      import("../views/Development/inventoryOpeningClosingView.vue"),
    beforeEnter: guardMyroute,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
